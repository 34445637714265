import React, { useEffect, useRef } from 'react';
import Matter, { Engine, Render, World, Bodies, Mouse, MouseConstraint, Runner, Events, Composite } from 'matter-js';
import images from './importImages'; // Import the dynamically loaded images

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const FallingImages: React.FC = () => {
  const scene = useRef<HTMLDivElement>(null);
  const engine = useRef<Engine | null>(null);
  const runner = useRef<Runner | null>(null);
  const isDragging = useRef(false);
  const imagesAdded = useRef(false);
  const maxImages = 30; // Reduce the max number of images for mobile
  const ground = useRef<any>(null);
  const ceiling = useRef<any>(null);
  const leftWall = useRef<any>(null);
  const rightWall = useRef<any>(null);

  const resizeHandler = () => {
    if (!scene.current || !engine.current) return;

    // Update the renderer dimensions
    const { innerWidth: width, innerHeight: height } = window;
    scene.current.style.width = `${width}px`;
    scene.current.style.height = `${height}px`;

    // Update the ground, ceiling, and walls
    // const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--grey50');

    if (ground.current) {
      Matter.Body.setPosition(ground.current, { x: width / 2, y: height });
      Matter.Body.setVertices(ground.current, [
        { x: 0, y: 0 },
        { x: width, y: 0 },
        { x: width, y: 10 },
        { x: 0, y: 10 }
      ]);
    }

    if (ceiling.current) {
      Matter.Body.setPosition(ceiling.current, { x: width / 2, y: 0 });
      Matter.Body.setVertices(ceiling.current, [
        { x: 0, y: 0 },
        { x: width, y: 0 },
        { x: width, y: 10 },
        { x: 0, y: 10 }
      ]);
    }

    if (leftWall.current) {
      Matter.Body.setPosition(leftWall.current, { x: 0, y: height / 2 });
      Matter.Body.setVertices(leftWall.current, [
        { x: 0, y: 0 },
        { x: 10, y: 0 },
        { x: 10, y: height },
        { x: 0, y: height }
      ]);
    }

    if (rightWall.current) {
      Matter.Body.setPosition(rightWall.current, { x: width, y: height / 2 });
      Matter.Body.setVertices(rightWall.current, [
        { x: 0, y: 0 },
        { x: 10, y: 0 },
        { x: 10, y: height },
        { x: 0, y: height }
      ]);
    }

    // Update existing bodies
    const allBodies = Composite.allBodies(engine.current.world);
    for (const body of allBodies) {
      if (!body.isStatic) {
        Matter.Body.setPosition(body, {
          x: Math.random() * width,
          y: Math.random() * height,
        });
      }
    }
  };

  useEffect(() => {
    if (!scene.current) return;

    // Create an engine
    engine.current = Engine.create();

    // Create a renderer
    const render = Render.create({
      element: scene.current,
      engine: engine.current,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        wireframes: false,
        background: getComputedStyle(document.documentElement).getPropertyValue('--grey50'), // Use CSS variable
      },
    });

    // Store reference to the render object
    // const renderRef = render;

    // Add ground, ceiling, and walls with minimal dimensions
    const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--grey50');

    ground.current = Bodies.rectangle(window.innerWidth / 2, window.innerHeight, window.innerWidth, 10, {
      isStatic: true,
      render: { fillStyle: backgroundColor },
    });
    ceiling.current = Bodies.rectangle(window.innerWidth / 2, 0, window.innerWidth, 10, {
      isStatic: true,
      render: { fillStyle: backgroundColor },
    });
    leftWall.current = Bodies.rectangle(0, window.innerHeight / 2, 10, window.innerHeight, {
      isStatic: true,
      render: { fillStyle: backgroundColor },
    });
    rightWall.current = Bodies.rectangle(window.innerWidth, window.innerHeight / 2, 10, window.innerHeight, {
      isStatic: true,
      render: { fillStyle: backgroundColor },
    });

    World.add(engine.current.world, [ground.current, ceiling.current, leftWall.current, rightWall.current]);

    // Function to add images to the world
    const addImages = () => {
      if (imagesAdded.current) return; // Guard check to prevent adding images more than once

      // Clear existing bodies except for the static ones
      const allBodies = Composite.allBodies(engine.current!.world);
      for (const body of allBodies) {
        if (!body.isStatic) {
          World.remove(engine.current!.world, body);
        }
      }
      const imagePaths = shuffleArray(Object.values(images)).slice(0, maxImages);

      imagePaths.forEach((path) => {
        const img = new Image();
        img.src = path;

        img.onload = () => {
          for (let i = 0; i < 2; i++) {
            const scaleFactor = 0.4 + Math.random() * 1.0; // Scale factor between 0.3 and 1.8
            const radius = Math.max(img.width, img.height) * scaleFactor / 2;
            const body = Bodies.circle(
              Math.random() * window.innerWidth,  // Random x position within the viewport width
              Math.random() * window.innerHeight, // Random y position within the viewport height
              radius,
              {
                density: 0.1, // Increase density to make the images heavier
                restitution: 0.8, // Increase bounciness
                friction: 0.2, // Adjust friction to reduce jiggling
                frictionAir: 0.02, // Increase air friction to increase drag
                frictionStatic: 1, // Increase static friction to reduce jiggling
                inertia: 0, // Prevent rotation
                render: {
                  sprite: {
                    texture: path,
                    xScale: scaleFactor,
                    yScale: scaleFactor,
                  },
                },
              }
            );
            World.add(engine.current!.world, body);
          }
        };

        img.onerror = () => {
          console.error(`Failed to load image: ${path}`);
        };
      });

      imagesAdded.current = true; // Mark images as added
    };

    // Add images initially only once
    addImages();

    // Add mouse control
    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine.current, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false
        }
      }
    });

    World.add(engine.current.world, mouseConstraint);

    // Keep the mouse in sync with rendering
    render.mouse = mouse;

    // Ensure the canvas allows pointer events
    render.canvas.style.pointerEvents = 'auto';

    // Handle dragging and prevent page scrolling
    const handleMouseDown = () => {
      isDragging.current = true;
    };

    const handleMouseUp = () => {
      isDragging.current = false;
    };

    render.canvas.addEventListener('mousedown', handleMouseDown);
    render.canvas.addEventListener('mouseup', handleMouseUp);

    // Stop movement on collision with walls
    Events.on(engine.current, 'collisionStart', (event) => {
      const pairs = event.pairs;
      pairs.forEach((pair) => {
        const { bodyA, bodyB } = pair;
        if ((bodyA === ground.current || bodyA === ceiling.current || bodyA === leftWall.current || bodyA === rightWall.current) ||
            (bodyB === ground.current || bodyB === ceiling.current || bodyB === leftWall.current || bodyB === rightWall.current)) {
          if (bodyA !== ground.current && bodyA !== ceiling.current && bodyA !== leftWall.current && bodyA !== rightWall.current) {
            Matter.Body.setVelocity(bodyA, { x: 0, y: 0 });
          }
          if (bodyB !== ground.current && bodyB !== ceiling.current && bodyB !== leftWall.current && bodyB !== rightWall.current) {
            Matter.Body.setVelocity(bodyB, { x: 0, y: 0 });
          }
        }
      });
    });

    // Create a runner
    runner.current = Runner.create();

    // Run the engine
    Runner.run(runner.current, engine.current);

    // Run the renderer
    Render.run(render);

    // Add resize event listener
    window.addEventListener('resize', resizeHandler);



    // @ts-ignore
    render.canvas.removeEventListener("mousewheel", mouse.mousewheel);
    // @ts-ignore
    render.canvas.removeEventListener("wheel", mouse.mousewheel);
    // @ts-ignore
    render.canvas.removeEventListener("DOMMouseScroll", mouse.mousewheel);

    // Cleanup on component unmount
    return () => {
      Render.stop(render);
      World.clear(engine.current!.world, false);
      Engine.clear(engine.current!);
      Runner.stop(runner.current!);
      render.canvas.removeEventListener('mousedown', handleMouseDown);
      render.canvas.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('resize', resizeHandler);
      render.canvas.remove();
      render.textures = {};
    };
  }, []);

  return <div ref={scene} style={{ width: '100vw', height: '100vh' }} />;
};

export default FallingImages;
