// src/TextBox.tsx
import React from 'react';
import './TextBox.css'; // Import the CSS for styling

const TextBox: React.FC = () => {
  return (
    <div className="text-box">
      <h2 className="subheading">Outfit of the day: simplified</h2>
      <a href="https://apps.apple.com/app/sticker-weather/id6612015866" target="_blank" rel="noopener noreferrer" className='app-store-button'>
        <img src="/app-store-badge.svg" alt="Download from the App Store" />
      </a>
    </div>
  );
};

export default TextBox;
