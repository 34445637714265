// src/Footer.tsx
import React from 'react';
import './Footer.css'; // Import the CSS for the footer

const Footer: React.FC = () => {
  return (
    <footer className="footer-section">
      <div className="content">
      <p><a href="/">Home</a> | <a href="/terms">Terms of Use</a> | <a href="/privacy">Privacy Policy</a></p>
        <div className="footer-columns">
          <div className="footer-column">
            <h4>Lucythemaker</h4>
            <a href="https://www.instagram.com/lucythemaker" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://www.youtube.com/@lucythemaker" target="_blank" rel="noopener noreferrer">YouTube</a>
            <a href="https://www.tiktok.com/@lucythemaker" target="_blank" rel="noopener noreferrer">TikTok</a>
          </div>
          <div className="footer-column">
            <h4>Selexin</h4>
            <a href="https://www.instagram.com/selexin_" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://www.youtube.com/@selexin" target="_blank" rel="noopener noreferrer">YouTube</a>
            <a href="https://www.tiktok.com/@selexin" target="_blank" rel="noopener noreferrer">TikTok</a>
          </div>
        </div>
        <p>&copy; 2024 Selexin. All rights reserved.</p>
        
      </div>
    </footer>
  );
};

export default Footer;
