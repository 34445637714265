// src/Home.tsx
import React from 'react';
import FallingImages from './FallingImages';
import TextBox from './TextBox';
import Footer from './Footer';
import './Home.css'; // Import the main CSS file
import Header from './Header';

const Home: React.FC = () => {
  return (
    <div className="main-container home">
      <section className="top-section">
        <Header />
        <FallingImages />
        <TextBox />
      </section>
      <section className="section dark">
        <div className="content">
          <h3>
          Sticker Weather makes mornings easy by turning the forecast into fun outfit stickers. Whether it's sunny or rainy, we've got your style covered!
          </h3>
        </div>
      </section>
      <section className="section light">
        <div className="content">
            <h2>Why You'll Love Sticker Weather</h2>
            <div className="features-grid">
            <div className="feature">
                <h3>Daily Outfit Suggestions</h3>
                <p>Get tailored outfit suggestions based on the current weather.</p>
            </div>
            <div className="feature">
                <h3>Detailed Layers</h3>
                <p>Unlock detailed layers you need to wear and handy warnings about what to pack for later in the day.</p>
            </div>
            <div className="feature">
                <h3>Customizable Stickers</h3>
                <p>Personalize your experience with a variety of cute and fun stickers!</p>
            </div>
            </div>
        </div>
        </section>
      <section className="section dark">
        <div className="content">
          <h2>What Our Users Are Saying</h2>
          <div className="testimonial">
            <h3>Emily</h3>
            <p>"Sticker Weather makes choosing my outfits so much easier! I love the cute stickers."</p>
          </div>
          <div className="testimonial">
            <h3>Sarah</h3>
            <p>"Finally, an app that helps me decide what to wear based on the weather. It's a game-changer!"</p>
          </div>
        </div>
      </section>
      <section className="section light">
        <div className="content">
          <h2>Ready to Weather-Proof Your Style?</h2>
          <p>Join the Sticker Weather community and make dressing for the weather fun and easy!</p>
          <a href="https://apps.apple.com/app/sticker-weather/id6612015866" target="_blank" rel="noopener noreferrer" className='cta-button'>
            <img src="/app-store-badge.svg" alt="Download from the App Store" />
          </a>
        </div>
      </section>
      <section className="section dark">
        <div className="content">
          <h2>Love what you see?</h2>
          <p>Check out more from @lucythemaker and @selexin!</p>
          <p>
            <a href="https://habbie.app" target="_blank" rel="noopener noreferrer" style={{'color': '#fff'}}>
              Habbie: Habit Tracker & Pet
            </a>
          </p>
          <p>Your widget pet that you take care of by keeping your daily habits!</p>
          <a href="https://apps.apple.com/us/app/habbie-habit-tracker-pet/id6468639991" target="_blank" rel="noopener noreferrer">
            <img src="/app-store-badge.svg" alt="Habbie: Habit Tracker & Pet" />
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
