// src/TextBox.tsx
import React from 'react';
import './TextBox.css'; // Import the CSS for styling

import logo from "./images/logo.png"

const Header: React.FC = () => {
  return (
    <div className="header">
      <h1 className="title">Sticker Weather</h1>
      <img className='header-logo' src={logo} alt="Sticker Weather" title='Sticker Weather' />
    </div>
  );
};

export default Header;
