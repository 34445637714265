// src/Terms.tsx
import React from 'react';
import Footer from './Footer';
import './Terms.css'; // Import the CSS for the terms page
import Header from './Header';

const Terms: React.FC = () => {
  return (
    <div className="terms-container">
      <div className="terms-section">
        <Header />
        <div className="content">
          <h2>Terms of Use</h2>
          <p>Sticker Weather is available on the Apple App Store and uses WeatherKit to provide accurate and up-to-date weather information. Our app offers paid subscriptions and in-app purchases to enhance your experience.</p>
          
          <h3>Subscriptions</h3>
          <p>Our subscription plans provide additional features, including premium stickers, detailed weather forecasts, and more personalized outfit suggestions. Subscriptions are billed monthly or annually, and payment will be charged to your Apple ID account at the confirmation of purchase.</p>
          <p>Your subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period. You can manage your subscription and turn off auto-renewal by going to your Account Settings after purchase. Your account will be charged for renewal within 24 hours prior to the end of the current period, and the cost of the renewal will be identified.</p>
          <p>If offered, you may be eligible for a free trial. The free trial will automatically convert to a paid subscription at the end of the trial period unless canceled at least 24 hours before the end of the trial. Any unused portion of a free trial period, if offered, will be forfeited when you purchase a subscription.</p>
          <p>You can cancel your subscription anytime through your Account Settings on the App Store. If you cancel during an active subscription period, you will still have access to Sticker Weather Premium until the end of the subscription period.</p>

          <h3>In-App Purchases</h3>
          <p>You can purchase additional sticker packs and other content through in-app purchases. These purchases are one-time payments and will be charged to your Apple ID account at the confirmation of purchase.</p>

          <h3>Privacy</h3>
          <p>We value your privacy and ensure that your personal information is protected. Please refer to our Privacy Policy for more details.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
