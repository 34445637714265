// src/PrivacyPolicy.tsx
import React from 'react';
import Footer from './Footer';
import './Terms.css'; // Reusing the CSS for the terms page
import Header from './Header';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="terms-container">
      <div className="terms-section">
        <Header />
        <div className="content">
          <h2>Privacy Policy</h2>
          <p><strong>Last Updated: August 14, 2024</strong></p>
          <p>Sticker Weather ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you use our app and services.</p>
          
          <h3>Information We Collect</h3>
          <p>We may collect the following types of information:</p>
          <ul>
            <li><strong>Device Information:</strong> We collect information about the device you use to access our app, including the device model, operating system, and unique device identifiers.</li>
            <li><strong>Location Data:</strong> With your permission, we collect and use your location data to provide location-specific weather forecasts and suggestions.</li>
          </ul>

          <h3>How We Use Your Information</h3>
          <p>We use your information to:</p>
          <ul>
            <li>Provide and improve our app and services.</li>
            <li>Process your subscriptions and in-app purchases.</li>
            <li>Personalize your experience by providing location-specific weather forecasts and suggestions.</li>
            <li>Communicate with you about updates, promotions, and other relevant information.</li>
          </ul>

          <h3>How We Share Your Information</h3>
          <p>We do not share your personal information with third parties except in the following circumstances:</p>
          <ul>
            <li>With service providers who assist us in operating our app and services, such as payment processors and analytics providers.</li>
            <li>As required by law or to comply with legal processes.</li>
            <li>To protect the rights, property, or safety of Sticker Weather, our users, or others.</li>
          </ul>

          <h3>Your Choices and Rights</h3>
          <p>You have the right to:</p>
          <ul>
            <li>Access and update your personal information.</li>
            <li>Opt-out of receiving promotional communications from us.</li>
            <li>Delete your account and personal data.</li>
            <li>Disable location data collection through your device settings.</li>
          </ul>

          <h3>Data Security</h3>
          <p>We implement appropriate security measures to protect your personal information. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure.</p>

          <h3>Children's Privacy</h3>
          <p>Our app is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.</p>

          <h3>Changes to This Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page, and the "Last Updated" date at the top will be revised.</p>

          <h3>Contact Us</h3>
          <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:privacy@stickerweather.com">privacy@stickerweather.com</a>.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
